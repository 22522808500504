<template>
  <v-row>
    <!-- basic -->
    <v-col cols="12">
      <v-card class="px-2">
        <v-card-title class="flex justify-space-between">
          <p>
            {{ $t('notifications') }}
          </p>
          <v-btn color="primary" @click.stop="drawer = !drawer">
            <v-icon dark class="pr-2">
              {{ icons.mdiPlusCircle }}
            </v-icon>
            {{ $t('add_notification') }}
          </v-btn>
        </v-card-title>
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th class="text-uppercase">Title</th>
                <th class="text-uppercase">Text</th>
                <th class="text-center text-uppercase">Send at</th>
                <th class="text-center text-uppercase">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loadingNotifications">
                <td colspan="3" class="text-center pt-4">
                  <v-progress-circular
                    v-if="loadingNotifications"
                    indeterminate
                    color="primary"
                    class="mb-4"
                  ></v-progress-circular>
                </td>
              </tr>
              <tr v-for="item in notifications" :key="item.id">
                <td>
                  {{ item.title }}
                </td>
                <td>
                  {{ item.text }}
                </td>
                <td class="text-center">
                  {{ item.date ? format(new Date(item.date), 'yyyy-MM-dd HH:mm') : '' }}
                </td>
                <td class="text-center">
                  <v-chip
                    small
                    class="font-weight-medium v-chip-light-bg"
                    :class="[!item.date ? 'success success-text' : 'info info-text']"
                  >
                    {{ !item.date ? 'SENT' : 'NOT SENT' }}
                  </v-chip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
    <v-navigation-drawer v-model="drawer" right absolute bottom temporary width="400" @close="alert('aa')">
      <v-card class="elevation-0">
        <div class="drawer-header d-flex align-center mb-4 elevation-0">
          <span class="font-weight-semibold text-base text--primary">{{ $t('add_notification') }}</span>
          <div class="spacer"></div>
          <v-btn small fab text @click="drawer = !drawer">
            <v-icon>
              {{ icons.mdiWindowClose }}
            </v-icon>
          </v-btn>
        </div>
        <v-card-text class="elevation-0">
          <v-form @submit.prevent="addNotification">
            <v-text-field
              v-model="form.title"
              :prepend-inner-icon="icons.mdiCardTextOutline"
              :label="$t('title')"
              outlined
              type="text"
              :error-messages="titleError"
              placeholder="Notification title..."
            ></v-text-field>
            <v-textarea
              v-model="form.text"
              :prepend-inner-icon="icons.mdiCardTextOutline"
              :label="$t('text')"
              outlined
              rows="2"
              type="text"
              :error-messages="textError"
              placeholder="Notification text..."
            ></v-textarea>

            <datetime-picker label="Send at" v-model="form.date"></datetime-picker>

            <v-subheader class="mt-2"> Filters </v-subheader>

            <v-divider class="mb-4"></v-divider>

            <v-select
              v-model="form.filters.country"
              :items="countries"
              item-text="name"
              item-value="id"
              :menu-props="{ maxHeight: '400' }"
              label="Countries"
              multiple
              outlined
              :prepend-inner-icon="icons.mdiEarth"
              persistent-hint
            ></v-select>

            <v-select
              v-model="form.filters.league"
              :items="leagues"
              item-text="name"
              item-value="id"
              :menu-props="{ maxHeight: '400' }"
              label="Leagues"
              multiple
              outlined
              :prepend-inner-icon="icons.mdiFormatAlignJustify"
              persistent-hint
            ></v-select>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="form.filters.distance_gte"
                  hide-details="true"
                  min="0"
                  :prepend-inner-icon="icons.mdiGreaterThanOrEqual"
                  label="High score"
                  outlined
                  dense
                  type="number"
                  placeholder="20"
                  class="p-0"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.filters.distance_lte"
                  hide-details="true"
                  min="0"
                  :prepend-inner-icon="icons.mdiLessThanOrEqual"
                  label="High score"
                  outlined
                  dense
                  type="number"
                  placeholder="20"
                  class="p-0"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.filters.honor_gte"
                  hide-details="true"
                  min="0"
                  :prepend-inner-icon="icons.mdiGreaterThanOrEqual"
                  label="Honor"
                  outlined
                  dense
                  type="number"
                  placeholder="20"
                  class="p-0"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.filters.honor_lte"
                  hide-details="true"
                  min="0"
                  :prepend-inner-icon="icons.mdiLessThanOrEqual"
                  label="Honor"
                  outlined
                  dense
                  type="number"
                  placeholder="20"
                  class="p-0"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn color="primary" class="mt-6" :disabled="loading" :loading="loading" type="submit">
              {{ $t('submit') }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </v-row>
</template>

<script>
import {
  mdiPlusCircle,
  mdiAlertOutline,
  mdiWindowClose,
  mdiCardTextOutline,
  mdiCamera,
  mdiCalendar,
  mdiGreaterThanOrEqual,
  mdiLessThanOrEqual,
  mdiEarth,
  mdiFormatAlignJustify,
} from '@mdi/js'
import { format, isBefore } from 'date-fns'

// import { serialize } from 'object-to-formdata'
import { API_URL } from '@/_plugins/axios'
import DatetimePicker from '@/views/notifications/components/DatetimePicker.vue'

export default {
  components: {
    DatetimePicker,
  },
  data() {
    return {
      format,
      isBefore,
      notifications: [],
      countries: [],
      leagues: [],
      icons: {
        mdiPlusCircle,
        mdiAlertOutline,
        mdiWindowClose,
        mdiCardTextOutline,
        mdiCamera,
        mdiCalendar,
        mdiGreaterThanOrEqual,
        mdiLessThanOrEqual,
        mdiEarth,
        mdiFormatAlignJustify,
      },
      form: {
        filters: {},
      },
      drawer: false,
      textError: null,
      titleError: null,
      loading: false,
      loadingNotifications: false,
      dateModal: false,
    }
  },
  mounted() {
    this.fetchNotifications()
    this.fetchCountries()
    this.fetchLeagues()
  },
  methods: {
    fetchCountries() {
      API_URL.get('country/get-all-countries').then(res => {
        this.countries = res.data
      })
    },
    fetchLeagues() {
      API_URL.get('league/all').then(res => {
        this.leagues = res.data
      })
    },
    fetchNotifications() {
      this.loadingNotifications = true
      this.fullImages = []
      API_URL.get('notifications').then(res => {
        this.notifications = res.data
        this.loadingNotifications = false
      })
    },
    addNotification() {
      this.loading = true
      this.textError = null
      this.titleError = null
      API_URL.post('notifications/store', this.form)
        .then(() => {
          this.fetchNotifications()
          this.drawer = false
          this.$toast.success('Successfully added new notification')
          this.loading = false
          this.form.text = null
          this.textError = null
        })
        .catch(err => {
          console.log(err.response.data.message)
          if (err.response.data.message.includes('text')) this.textError = err.response.data.message
          if (err.response.data.message.includes('title')) this.titleError = err.response.data.message
          this.loading = false
          console.log(err)
        })
    },
  },
}
</script>
<style scoped>
.drawer-header {
  -webkit-text-size-adjust: 100%;
  word-break: normal;
  tab-size: 4;
  font-size: 16px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  --v-anchor-base: #9155fd;
  --v-primary-base: #9155fd;
  --v-secondary-base: #8a8d93;
  --v-accent-base: #0d6efd;
  --v-error-base: #ff4c51;
  --v-info-base: #16b1ff;
  --v-success-base: #56ca00;
  --v-warning-base: #ffb400;
  --vh: 3.07px;
  font-family: Inter, sans-serif;
  line-height: 1.5;
  pointer-events: auto;
  overflow-wrap: break-word;
  white-space: normal;
  color: rgba(231, 227, 252, 0.68) !important;
  background-repeat: no-repeat;
  box-sizing: inherit;
  margin: 0;
  padding: 0.75rem 1.25rem;
  display: flex !important;
  align-items: center !important;
  margin-bottom: 16px !important;
  background-color: rgba(231, 227, 252, 0.04);
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
</style>
